$theme-colors: (
  "primary": #008198,
  "secondary": #d9f0f4
);
$enable-rounded: false;

@import "../bootstrap/scss/bootstrap";

.wu-teaser {
  @extend .card;
}

.wu-teaser-primary {
  @extend .card;
  @extend .mb-4;
  background-color: #efefef;
  .wu-more-link {
    background-color: $gray-900;
  }
}

.wu-more-link {
  background-color:white;
  mask-image: url(/resources/openacs-wu-theme/icons/icon-3.svg);
  mask-repeat: no-repeat;
  mask-size: contain;
  display: inline-block;
  width: 0.5rem;
  height: 1rem;
  margin-left: 0.5rem;
  margin-bottom: -0.225rem;
}

.sortable-handle {
  cursor: move !important;
}
